import React, { FC, ReactNode } from 'react';

interface Props {
  header: ReactNode;
  description: ReactNode | string;
  image: ReactNode; // TODO: Change the name - it is not always an image
  indicatorIcon?: ReactNode;
  button?: ReactNode;
  className?: string;
}
const PanelTextSection: FC<Props> = ({ header, description, image, indicatorIcon, button, className }) => (
  <div className={`py-16 sm:py-8 px-variable-md grid justify-center ${className ?? ''}`}>
    <div className="max-w-5xl flex max-sm:flex-col-reverse max-sm:gap-y-16 sm:gap-12 lg:gap-24 sm:odd:flex-row-reverse">
      <div className="flex-1 sm:basis-1/5 md:basis-0 content-center place-content-center sm:place-content-start">
        {image}
      </div>
      <div className="flex-1 grid content-center justify-items-start ltr sm:odd:rtl gap-5 max-sm:mt-5">
        {indicatorIcon}
        <h3 className="font-serif text-display-xs">{header}</h3>
        <div className="rich-text">{React.isValidElement(description) ? description : <p>{description}</p>}</div>
        {button != null && button}
      </div>
    </div>
  </div>
);

export default PanelTextSection;
