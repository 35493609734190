import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Block from './Block';
import Content from './Content';
import Header from './Header';
import { useGetPostDetails } from './PostDetailsData';
import RelatedPosts from './RelatedPosts';
import NotFound from '#pages/NotFound';
import { Spinner } from '#components/Index';

const Post: FC = () => {
  const { slug = '' } = useParams();
  const { data, isLoading } = useGetPostDetails(slug);

  if (isLoading) {
    return <Spinner className="min-h-full" />;
  }

  if (data == null) {
    return <NotFound className="min-h-full" />;
  }

  const articleStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    'headline': data.post.metaTitle,
    'description': data.post.metaDescription,
    'image': [data.post.imageUrl],
    'datePublished': data.post.createdAt,
    'author': {
      '@type': 'Person',
      'name': data.post.author.name,
      'jobTitle': data.post.author.title,
    },
  };

  const breadcrumbStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': [
      {
        '@type': 'ListItem',
        'position': 1,
        'name': 'Hiperpool',
        'item': 'https://hiperpool.com/',
      },
      {
        '@type': 'ListItem',
        'position': 2,
        'name': 'Insights & Resources',
        'item': 'https://hiperpool.com/blog',
      },
      {
        '@type': 'ListItem',
        'position': 3,
        'name': data.post.metaTitle,
        'item': `https://hiperpool.com/blog/${slug}`,
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>{data.post.metaTitle}</title>
        <meta name="description" content={data.post.metaDescription} />
        <script type="application/ld+json">{JSON.stringify(articleStructuredData)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumbStructuredData)}</script>
      </Helmet>
      <Header
        title={data.post.title}
        readTime={data.post.readTime}
        author={data.post.author.name}
        publishDate={data.post.createdAt}
        imageUrl={data.post.imageUrl}
        category={data.post.mainCategory}
      />
      <Content content={data.post.content} author={data.post.author} />
      {data.post.locked && <Block />}
      {data.post.relatedPosts.length > 0 && <RelatedPosts posts={data.post.relatedPosts} />}
    </>
  );
};

export default Post;
