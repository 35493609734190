import React, { FC } from 'react';
import { Button, ArticleCard, Spinner } from '#components/Index';
import { useGetFeaturedPosts } from './FeaturedPostsData';
import { paths } from '#pages/paths';

const Articles: FC = () => {
  const { data } = useGetFeaturedPosts();

  const renderArticles = () => {
    if (data == null) {
      return <Spinner className="py-32" />;
    }
    return (
      <div className="grid xs:grid-cols-2 lg:grid-cols-4 gap-x-7 lg:gap-x-8 gap-y-12 lg:gap-y-14 max-w-7xl">
        {data.posts.map((post) => (
          <ArticleCard
            key={post.slug}
            title={post.title}
            image={post.imageUrl}
            link={paths.blogPost({ slug: post.slug })}
            simple={true}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="bg-gray-50 py-16 px-variable-sm grid gap-12 justify-center">
      <h2 className="text-display-md font-serif text-center">Featured guides & resources</h2>
      {renderArticles()}
      <div className="flex justify-center">
        <Button variant="secondary" size="large" href={paths.blog()}>
          View all resources
        </Button>
      </div>
    </div>
  );
};

export default Articles;
