import React, { FC } from 'react';
import { useGetCoachingTypes } from '#pages/homepage/interview/elements/CoachingTypesData';
import { Button, Spinner } from '#components/Index';
import { paths, parseParamsToLinkSearch } from '#pages/paths';

const FilteredCoachesButtons: FC = () => {
  const { data } = useGetCoachingTypes();

  if (data == null) {
    return <Spinner className="py-32" />;
  }

  const { coachingTypes } = data;

  return (
    <div className="flex flex-col gap-y-2 sm:grid sm:grid-cols-2 sm:gap-4 sm:ltr">
      {coachingTypes.map(({ name, id }) => (
        <Button
          variant="alternate"
          key={name}
          to={paths.coaches() + parseParamsToLinkSearch({ coachingType: id })}
          className="w-100 text-center"
        >
          {name}
        </Button>
      ))}
    </div>
  );
};

export default FilteredCoachesButtons;
