import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '#components/Index';
import { paths } from '#pages/paths';
import CandidateFAQ from './CandidateFAQ';

const SupportForCandidate: FC = () => {
  return (
    <>
      <div className="bg-gray-50 px-variable-sm">
        <div className="py-14 m-auto max-w-4xl">
          <h1 className="text-display-md font-serif text-center">Frequently asked questions</h1>
          <p className="text-center mt-6 mb-8">
            Are you an employer with questions about listing your job on Hiperpool? <br />
            <Link className="link" to={paths.supportForEmployer()}>
              Visit the employer FAQs
            </Link>
          </p>
          <CandidateFAQ />
          <div className="text-center my-12">
            <Button to={paths.contactUs()} variant="secondary">
              Still have questions? Contact us
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportForCandidate;
